<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
      :baseroute="baseroute"
      :btn-custom="true"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      title:'Kantor Jaga',
      dataurl:'/service-guard',
      baseroute:'kantorjaga',
      fields:[
        { key: 'service_center', label: 'ULP', type: 'select2', rules:'',dataurl:'/service-center', placeholder:'Select ULP'},
        { key: 'code', label: 'Kode Kantor Jaga', type: 'input', rules:'required' , placeholder:'Kode Kantor Jaga'},
        { key: 'name', label: 'Nama', type: 'input', rules:'required' , placeholder:'Nama'},
        { key: 'address', label: 'Alamat', type: 'autocomplate', rules:'required' , placeholder:'Alamat'},
        { type:'map', label:'Map'},
        { key: 'leader', label: 'Pimpinan', type: 'select2', rules:'',dataurl:'/staff', placeholder:'Select Pimpinan'},
        { key: 'pic', label: 'PIC', type: 'select2', rules:'',dataurl:'/staff', placeholder:'Select PIC'},
      ]
    }
  },
}
</script>